<template>
    <section class="main-content-section">
        <div class="grid grid-pad">
            <div class="col-1-1 center">
                <h2 class="page-title">{{cancelSubscription}}</h2>
            </div>

            <LoadingSpinner v-if="isLoading" />

            <div v-else-if="showSuccessMessage" class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage flex flex-column align-center justify-center">
                    <p>{{subscriptionSuccesfullyRemoved}}</p>
                    <div class="success-icon flex justify-center align-center">
                        <font-awesome-icon icon="check-circle" />
                    </div>
                    <router-link to="/" class="btn btn-primary">{{home}}</router-link>
                </div>
            </div>
            
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage flex flex-column align-center justify-center">
                    <p>{{doYouReallyWantToCancelYourNotificationSubscription}}</p>
                    
                    <div class="btn-container flex">
                        <router-link to="/" class="btn btn-primary">{{home}}</router-link>
                        <button @click="cancelSubcription" class="btn btn-primary alternative">{{unsubscribe}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import agent from '@/api/agent.js';
import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

export default {
    name: 'CancelNotificationSubscription',
    components: { LoadingSpinner },
    data() {
        return {
            showSuccessMessage: false,
        };
    },
    created() {
        this.setDocumentTitle();
    },
    computed: {
        ...mapGetters([
            'metaTitle',
            'isLoading',
            // Texts
            'cancelSubscription',
            'doYouReallyWantToCancelYourNotificationSubscription',
            'home',
            'unsubscribe',
            'subscriptionSuccesfullyRemoved'
        ])
    },
    methods: {
        setDocumentTitle() {
            document.title = 'Cancel Notification' + this.metaTitle;
        },
        async cancelSubcription() {
            const email = this.$route.query.email;
            try {
                await agent.products.removeNotification(email);
                this.showSuccessMessage = true;    
            } catch (error) {
                console.error(error);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/global-styles/variables';

.textpage {
    border-radius: 8px;
    box-shadow: $shadow-1;
    padding: 6rem;
    background: $white;
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
    text-align: center;
    
    p {
        margin-bottom: 5rem;
    }

    .success-icon {
        margin-bottom: 5rem;
        color: rgb(24, 185, 24);
        font-size: 10rem;
    }
    
    p a,
    li a {
        display: inline-block;
        color: inherit;
        text-decoration: underline;
    }

    &-title {
        font-size: 2.8rem;
        font-weight: 300;
        line-height: normal;
        display: block;
        text-align: center;
        margin: 0 0 2rem 0;
    }
}

.btn-container a {
    margin-right: 2rem;
}

@media only screen and (max-width: 400px) {
    .textpage {
        padding: 6rem 2rem;
    }
}
</style>